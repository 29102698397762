import axios from 'axios'
import {ElMessage} from "element-plus";
const api = axios.create({
    baseURL:process.env.VUE_APP_URL,
})
api.interceptors.request.use( config=>{

    // console.log( 'config:',config )

    const url:string = config.url?config.url:'';



    if( url.indexOf('token=') <0 ){

        config.url += url.indexOf('?') >-1? '&':'?';
        const token = localStorage.getItem('token');
        config.url += 'token=' + token;

    }


    return config
}, err=>{

    Promise.reject( err )
})

api.interceptors.response.use(res=>{

    console.log( 'response:',res )
    if( res.data.code ){

        ElMessage({
            message : !res.data.code ? '成功' : res.data.message,
            type    : !res.data.code ? 'success' : 'error',
            duration: 600,
            onClose : function(){

                // if( !res.data.code ){
                //     location.href = '#/container/auth/user';
                // }

            }
        })
    }
    // return Promise.resolve( res )
    return res 

}, err => {

    Promise.reject( err )
})

// const request =
export default api;