import axios from 'axios';
const checkLogin = ( callback:Function ) => {

    const token:string|null = localStorage.getItem('token');
    let url:any = process.env.VUE_APP_URL + '/api/check/login';
    url += '?token=' + token;
    axios.get( url ).then(res=>{

        if( typeof callback == 'function' ){
            callback( res.data );
        }

    });


}

const logout = ( callback:Function ) => {

    const token:string|null = localStorage.getItem('token');
    let url:any = process.env.VUE_APP_URL + '/api/logout';
    url += '?token=' + token;
    axios.get( url ).then(res=>{

        if( typeof callback == 'function' ){
            callback( res.data );
        }

    });

}

export { logout };
export default checkLogin;