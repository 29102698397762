import {defineStore} from "pinia";

export const useUserStore = defineStore('user',{
    state:()=>{
        return {
            name    : '',
            id      : 0,
            isBoss:true
        }
    },
    getters:{},
    actions:{
        // registerUser( id, name ){
        //
        //     this.
        // }
    }
})