import { createApp } from 'vue'
import { createPinia } from 'pinia'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'dayjs/locale/zh-cn';//中文
import locale from 'element-plus/lib/locale/lang/zh-cn';//中文
import './assets/style/common.less'
import {
    Document,
    Place,
    Avatar,
    Notification,
    BellFilled,
    SetUp,
    Right,
    Lock,
    User,
    HomeFilled,
    Printer
} from '@element-plus/icons-vue';
import App from './App.vue'
// axios.defaults.withCredentials=true;
const app = createApp(App)
const pinia = createPinia()
app.use(pinia)
app.use(ElementPlus,{ locale })
// app.use(ElMessage)
app.use(router)
app.use(VueAxios,axios)
app.component('icon-document',Document)
app.component('icon-place',Place)
app.component('icon-avatar',Avatar)
app.component('icon-notification',Notification)
app.component('icon-bellFilled',BellFilled)
app.component('icon-setUp',SetUp)
app.component('icon-right',Right)
app.component('icon_lock',Lock)
app.component('icon_user',User)
app.component('icon-homeFilled',HomeFilled)
app.component('icon-printer',Printer)

app.mount('#app')
