const ws = new WebSocket('ws://124.223.113.164:8383');
ws.onopen = ( ) => {

    console.log( 'websocket open', ws.readyState );
    // ws.send( user )

};

ws.onmessage = ( data  ) => {

    console.log( 'message:', data )
};

ws.onclose = ( ) => {

    console.log( 'onclose' )

};

ws.onerror = ( ) => {

    console.log( 'onerror' )

};

export default ws;