
import { Options, Vue } from 'vue-class-component';
// import HelloWorld from './components/HelloWorld.vue';
// import Login from './components/Login.vue';
// import Register from './components/Register.vue';

@Options({

})
export default class App extends Vue {}
