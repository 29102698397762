import { createRouter, createWebHashHistory } from 'vue-router'

import Login from './components/Login.vue' ;
import Register from './components/Register.vue' ;
import Container from './components/Container.vue' ;
import Home from './components/container/home.vue' ;
import guzhangList from './components/container/Guzhang/List.vue' ;
import guzhangSingle from './components/container/Guzhang/Single.vue' ;
import guzhangTypeList from './components/container/Guzhang/TypeList.vue' ;
import guzhangTypeEdit from './components/container/Guzhang/TypeEdit.vue' ;

import RepairerList from './components/container/Repairer/List.vue' ;
import RepairerSingle from './components/container/Repairer/Single.vue' ;
import RevisitorList from './components/container/Revisitor/List.vue' ;

import InspectionList from './components/container/Inspection/List.vue' ;
import InspectionEdit from './components/container/Inspection/Edit.vue' ;
import InspectionAssignment from './components/container/Inspection/Assignment.vue' ;

import InspectionTaskList from './components/container/InspectionTask/List.vue' ;
import InspectionTask from './components/container/InspectionTask/Single.vue' ;

import OrderList from './components/container/Order/List.vue' ;
import OrderEdit from './components/container/Order/Edit.vue' ;
import OrderFeedback from './components/container/Order/Feedback.vue' ;

import AuthGroupList from './components/container/Auth/GroupList.vue' ;
import AuthGroupEdit from './components/container/Auth/GroupEdit.vue' ;

import AuthUserList from './components/container/Auth/UserList.vue' ;
import AuthUserEdit from './components/container/Auth/UserEdit.vue' ;

import MessageList from './components/container/Message/List.vue' ;

const routes = [
    {
        path: '/',
        redirect: { path:'/login'}
    },
    { path: '/login', component: Login },
    { path: '/register', component: Register },

    {
        path: '/container',
        name:'container',
        component: Container,
        children:[
            { name:'综合', path: 'home', component: Home },
            { name:'故障列表', path: 'guzhang/list', component: guzhangList },
            { name:'故障详情', path: 'guzhang/:id', component: guzhangSingle },
            { name:'故障类型列表', path: 'guzhang/type/list', component: guzhangTypeList },
            { name:'故障类型添加', path: 'guzhang/type/add', component: guzhangTypeEdit },
            { name:'故障类型编辑', path: 'guzhang/type/edit', component: guzhangTypeEdit },
            { name:'维修人员列表', path: 'repairer/list', component: RepairerList },     
            { name:'维修人员详情', path: 'repairer/single/:id', component: RepairerSingle },     
            { name:'回访人员列表', path: 'revisitor/list', component: RevisitorList },     
            { name:'工单', path: 'order/list', component: OrderList },
            { name:'工单创建', path: 'order/create', component: OrderEdit },
            { name:'故障工单创建', path: 'fault/:fault_id/order/create', component: OrderEdit },
            { name:'工单编辑', path: 'order/edit/:id', component: OrderEdit },

            { name:'工单回访', path: 'order/:order_id/feedback', component: OrderFeedback },

            { name:'巡检列表', path: 'inspection/list', component: InspectionList },
            { name:'巡检创建', path: 'inspection/create', component: InspectionEdit },
            { name:'巡检编辑', path: 'inspection/edit/:id', component: InspectionEdit },
            { name:'巡检分派', path: 'inspection/assignment/:id', component: InspectionAssignment },

            { name:'巡检记录列表', title:'巡检记录列表', path: 'inspection/task/list', component: InspectionTaskList },
            { name:'巡检记录详情', path: 'inspection/task/:id', component: InspectionTask },

            { name:'权限组', path: 'auth/group', component: AuthGroupList },
            { name:'权限组创建', path: 'auth/group/create', component: AuthGroupEdit },
            { name:'权限组编辑', path: 'auth/group/edit/:id', component: AuthGroupEdit },
            { name:'管理员列表', path: 'auth/user', component: AuthUserList },
            { name:'管理员创建', path: 'auth/user/create', component: AuthUserEdit },
            { name:'管理员编辑', path: 'auth/user/edit/:id', component: AuthUserEdit },
            { name:'消息通知', path: 'message/list', component: MessageList },

            { name:'用户列表', path: 'customer/yonghu', component: () => import('./components/container/Customer/YonghuList.vue') },
            { name:'用户编辑', path: 'customer/yonghu/:id', component: () => import('./components/container/Customer/YonghuEdit.vue') },

        ]

    },


]
const router = createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHashHistory(),
    routes, // `routes: routes` 的缩写
})
export default router